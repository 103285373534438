import { render, staticRenderFns } from "./ClasswiseLeaderboard.vue?vue&type=template&id=06843a74&scoped=true&"
import script from "./ClasswiseLeaderboard.vue?vue&type=script&lang=js&"
export * from "./ClasswiseLeaderboard.vue?vue&type=script&lang=js&"
import style0 from "./ClasswiseLeaderboard.vue?vue&type=style&index=0&id=06843a74&scoped=true&lang=css&"
import style1 from "./ClasswiseLeaderboard.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06843a74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDataTable,VIcon,VTab,VTabs})
